@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Quicksand&display=swap");

html::-webkit-scrollbar {
    width: 1vw;
    /* scrollbar-color: #C2B8A3 #E6DDC6; */
}

html::-webkit-scrollbar-thumb {
    background-color: #A1988299;
    border-radius: 100px;
}

html::-webkit-scrollbar-track {
    background-color: #DAD6CE99;
}

html::-webkit-scrollbar-thumb:hover {
    background-color: #A19882;
}

div::-webkit-scrollbar {
    width: 0.6vw;
    /* scrollbar-color: #C2B8A3 #E6DDC6; */
}

div::-webkit-scrollbar-thumb {
    background-color: #A19882;
    border-radius: 100px;
}

div::-webkit-scrollbar-track {
    background-color: #DAD6CE11;
}

div::-webkit-scrollbar-thumb:hover {
    background-color: #A19882;
}

body {
    margin: 0;
}

table {
    z-index: 3;
}

th {
    font-family: 'DM Serif Display', serif;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.centerR {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.flex-section {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
}

.flex-col-scroll {
    flex-grow: 1;
    overflow: auto;
    min-height: 100%;
}

.modelName {
    font-family: 'DM Serif Display', serif;
    opacity: 49%;
    font-size: 6em;
    font-weight: 300;
    margin: 0 19px;
}

/* Container for PickADisplay, SelectATheme, Styling, DisplaySettings */
.menuItemContainer {
    border-radius: 30px;
    padding: 20px;
    margin: 20px;
    box-shadow: 2px 2px 20px #C2B8A3, -2px 2px 20px #C2B8A3;
}

/* Container for DownloadImage, SaveAsPDF */
.downloadContainer {
    display: "flex";
    flex-direction: "row";
    border: 1px solid black;
    border-radius: 100px;
    margin: 10px;
    background-color: "transparent";
}

.downloadContainerDarkMode {
    border: 1px solid #DAD6CE;
}



@media screen and (min-width: 600px) {
    .modelName {
        writing-mode: vertical-lr;
        text-orientation: mixed;
        transform: rotate(-180deg);
        transform-origin: 20% 110%;
    }
}

@media print {
    body {
        visibility: hidden;
    }

    #TimetableBackground * {
        print-color-adjust: exact;
        visibility: visible;
    }
}

/* Timepicker hover color unable to change through theme */
.css-t8fa40-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected:focus-visible,
.css-t8fa40-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected:hover {
    background-color: #C2B8A3 !important;
}