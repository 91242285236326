.div {
    display: "flex";
    flex-direction: "row";
    border: 1px solid black;
    border-radius: 100px;
    margin: 10px;
}

.darkModeDiv {
    border: 1px solid #DAD6CE;
}

/* C2B8A3 */