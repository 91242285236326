.customRadio {
    display: inline-block;
    height: 22px;
    width: 22px;
    border-radius: 6px;
    margin: 8px;
    cursor: pointer;
}

input[type="radio"] {
    display: none;
}