.background {
    z-index: 1;
    border-radius: 24px;
    position: relative;
    margin: 40px 20px 20px 20px;
}

.backgroundInvisible {
    z-index: 1;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.name {
    position: absolute;
    font-family: 'DM Serif Display', serif;
    font-size: 11px;
    font-weight: 400;
    margin: 0px;
    opacity: 0.6;
}

.dateTime {
    position: absolute;
    z-index: 2;
}

.img {
    position: absolute;
    z-index: -1;
    box-sizing: border-box;
}